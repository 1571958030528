import React from 'react'
import AppBar from '@mui/material/AppBar'
import Typography from '@mui/material/Typography'
import { Box, Button, Container } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { setLogout } from '../app/reducers/authSlice'
import { useAppDispatch, useAppSelector } from '../app/hooks'

const Header = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const handleLogout = () => {
        dispatch(setLogout())
    }
    const isAuthenticated = useAppSelector((state: any) => state.user.auth)
    return (
        <>
            <AppBar position="static" color="primary" sx={{ background: 'transparent',boxShadow: '0px 50px 106px 11px rgba(46, 67, 117, 0.15)',borderRadius:'20px' }}>
                <Container fixed>
                    <Box sx={{ display: "flex", alignItems: 'center', py: '15px', gap: '30px' }}>
                        <Typography onClick={() => navigate("/")} variant="h4" color="#000" sx={{ cursor: 'pointer' }}>
                            <b>FCAttendy</b>
                        </Typography>
                        <Box flexGrow="1" />

                        {isAuthenticated &&
                            <Button onClick={handleLogout} size="small" variant="contained" color="primary">
                                Logout
                            </Button>}
                        {/* {!isAuthenticated &&
                            <Button onClick={() => navigate("/login")} size="small" variant="contained" color="primary">
                                Login
                            </Button>
                        } */}
                        {/* <Button onClick={() => navigate("/signup")} size="small" variant="outlined" color="primary">
                                    Signup
                                </Button> */}
                    </Box>
                </Container>
            </AppBar>
        </>
    )
}

export default Header