import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: fetchBaseQuery({
    // baseUrl: "http://localhost:9000/api/v1/",
    baseUrl: "https://fcattendyapi.fewerclicks.in/api/v1/",
  }),
  endpoints: (builder) => ({
    getStates: builder.query({
      query: () => "data/all-states",
    }),
    getDistricts: builder.query({
      query: ({ state }) => `data/all-districts/${state}`,
    }),
    getCenters: builder.query({
      query: ({ district }) => `data/all-centers/${district}`,
    }),
    getBatches: builder.query({
      query: ({ center }) => `data/all-batches/${center}`,
    }),
    getStudents: builder.query({
      query: ({ batch }) => `data/all-students/${batch}`,
    }),
    //
    getBatchData: builder.query({
      query: ({ centerId, batchId, date }) =>
        `center/${centerId}/${batchId}?date=${date}`,
    }),
    getAllCenters: builder.query({
      query: () => `center/all`,
    }),
  }),
});

export const {
  useGetStatesQuery,
  useGetDistrictsQuery,
  useGetCentersQuery,
  useGetBatchesQuery,
  useGetBatchDataQuery,
  useGetStudentsQuery,
  useGetAllCentersQuery,
} = baseApi;
