import React from 'react';
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { Box, Typography, Button, TextField } from '@mui/material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Checkbox, IconButton, InputAdornment, } from '@mui/material';
// import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { errorAlert, successAlert } from '../components/toastGroup';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { setAuth, setToken, setRole, setProfile } from '../app/reducers/authSlice';
const { useFormik } = require('formik');

const Login = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const isAuthenticated = useAppSelector((state: any) => state.user.auth)
    React.useEffect(() => {
        isAuthenticated && navigate("/dashboard/batch-selection")
    }, [isAuthenticated, navigate])
    const [password, setPassword] = React.useState(false);
    const validationSchema = yup.object({
        email: yup.string().email().required('Enter a valid email.'),
        password: yup.string().required('Password is required.'),
        rememberMe: yup.boolean().default(false),
    });
    const staticEmail = "info@apnaprayas.com"
    const staticPassword = "Apna@1024"
    const formik = useFormik({
        initialValues: {
            email: staticEmail,
            password: staticPassword,
            rememberMe: true,
        },
        validationSchema: validationSchema,
        onSubmit: async (values: any, { resetForm }: any) => {
            if (staticEmail === values.email && staticPassword === values.password) {
                successAlert("Logged in successfully", "top-center")
                dispatch(setAuth(true));
                dispatch(setToken("656a5sd4as1najndjnajsna128391283921jnj"));
                dispatch(setRole("Mentor"));
                dispatch(setProfile("Ashish"))
                navigate("/dashboard/batch-selection")
                resetForm()
            } else {
                errorAlert("Wrong credentials.", "top-center")
                resetForm()
            }
        },
    });
    return (
        <>
            <Box sx={{ postion: 'reltaive', }}>
                <Container fixed sx={{ my: { md: '0', xs: '30px' } }}>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        alignContent="center"
                        wrap="wrap"
                        sx={{ height: { md: '100vh', xs: 'auto' } }}
                    // background: "linear-gradient(180deg, #EACFFF 0%, rgba(155, 207, 255, 0.00) 100%)"
                    >
                        <Grid item xl={6} md={6} xs={12}>
                            <form onSubmit={formik.handleSubmit}>
                                <Box>
                                    <Typography gutterBottom fontSize='32px' lineHeight='44px' fontWeight='400' color='text.primary'>
                                        Welcome to FCAttendy!
                                    </Typography>
                                    <Typography gutterBottom variant="body1" color='#6F6F6F'>
                                        Login to check your batch progress.
                                    </Typography>
                                    <br />
                                    <Box sx={{ width: { md: '456px', xs: "100%" } }}>
                                        <br />
                                        <Box>
                                            <Typography gutterBottom variant="body1" color='#6F6F6F'>
                                                Email Address
                                            </Typography>
                                            <TextField
                                                id='email'
                                                fullWidth
                                                placeholder='Enter your email address'
                                                name='email'
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                error={formik.touched.email && Boolean(formik.errors.email)}
                                                helperText={formik.touched.email && formik.errors.email}
                                            />
                                        </Box>
                                        <br />
                                        <Box>
                                            <Typography gutterBottom variant="body1" color='#6F6F6F'>
                                                Password
                                            </Typography>
                                            <TextField
                                                id='password'
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position='start'>
                                                            <IconButton onClick={() => setPassword(!password)}>
                                                                {password ? (
                                                                    <VisibilityOff sx={{ color: '#939393' }} />
                                                                ) : (
                                                                    <Visibility sx={{ color: '#939393' }} />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                fullWidth
                                                placeholder='Enter your Password'
                                                type={password ? 'text' : 'password'}
                                                name='password'
                                                onBlur={formik.handleBlur}
                                                autoComplete='current-password'
                                                value={formik.values.password}
                                                onChange={formik.handleChange}
                                                error={formik.touched.password && Boolean(formik.errors.password)}
                                                helperText={formik.touched.password && formik.errors.password}
                                            />
                                            {/* <Link to='/login' style={{ textDecoration: 'none' }}>
                                                <Typography mt='10px' fontSize='12px' lineHeight='12px' fontWeight='300' color='#6F6F6F'>
                                                    Forgot Password?
                                                </Typography>
                                            </Link> */}
                                        </Box>
                                        <br />
                                        <Box display='flex' alignItems='center'>
                                            <Checkbox size='small' onChange={(e) => formik.setFieldValue('rememberMe', e.target.checked)} />
                                            <Typography fontSize='14px' lineHeight='24px' fontWeight='300' color='#6F6F6F'>
                                                Remember me
                                            </Typography>
                                        </Box>
                                        <br />
                                        <Button
                                            type='submit'
                                            fullWidth
                                            size='large'
                                            sx={{ justifyContent: 'space-between' }}
                                            variant='contained'
                                            color='primary'
                                        >
                                            Sign In <ArrowRightAltIcon />
                                        </Button>
                                        {/* <br /> <br /> */}
                                        {/* <Typography
                                            textAlign='center'
                                            gutterBottom
                                            fontSize='16px'
                                            lineHeight='24px'
                                            fontWeight='300'
                                            color='#6F6F6F'
                                        >
                                            Dont have an account ?
                                            <Link to='/signup' style={{ textDecoration: 'none', color: '#6F6F6F', marginLeft: '5px' }}>
                                                <b>Sign Up</b>
                                            </Link>
                                        </Typography> */}
                                    </Box>
                                </Box>
                            </form>
                        </Grid>
                    </Grid>
                </Container>
                <Box sx={{ position: 'absolute', right: '0', top: '0', zIndex: '-1', display: { md: 'block', xs: 'none' } }}>
                    <img src={require("../assets/homebg.png")} alt="img" style={{ height: '99.4vh', }} />
                    {/* <img src={require("../assets/homebg.png")} alt="img" style={{ height: '100vh', }} /> */}
                </Box>
            </Box>
        </>
    )
}

export default Login