import { useNavigate } from 'react-router-dom'

const PageNotFound = () => {
    const navigate = useNavigate()
    setTimeout(() => {
        navigate(-1)
    }, 5000);
    return (
        <>
            <img src="https://static.vecteezy.com/system/resources/previews/004/639/366/non_2x/error-404-not-found-text-design-vector.jpg" alt="page-not-found" style={{ width: '100%' }} />
        </>
    )
}

export default PageNotFound