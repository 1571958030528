import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: "",
    auth: false,
    profile: "",
    role: "",
  },
  reducers: {
    setAuth: (state, action) => {
      state.auth = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setLogout: (state) => {
      state.token = "";
      state.auth = false;
      state.profile = "";
      state.role = "";
    },
  },
});

export const { setAuth, setRole, setProfile, setToken, setLogout } =
  authSlice.actions;

export default authSlice.reducer;
