export function errorParser(errorObject: any) {
  try {
    const errorString = JSON.stringify(errorObject);
    const errorParsed = JSON.parse(errorString);
    if (
      errorParsed &&
      errorParsed.data &&
      errorParsed.data.error &&
      errorParsed.data.error.message
    ) {
      return errorParsed.data.error.message;
    } else {
      return "Please try again later.";
    }
  } catch (error) {
    return "Please try again later.";
  }
}
