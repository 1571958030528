import authReducer from "./reducers/authSlice";
import { baseApi } from "./baseApi";
import { persistReducer, persistStore } from "redux-persist";
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { setupListeners } from "@reduxjs/toolkit/query";
// import { getDefaultMiddleware } from "@reduxjs/toolkit";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const persistConfigAuth = {
  key: "root",
  storage,
};

const persistedReducerUser = persistReducer(persistConfigAuth, authReducer);

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    user: persistedReducerUser,
  },
  middleware: (gDM) =>
    gDM({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(baseApi.middleware),
  // middleware: getDefaultMiddleware({ serializableCheck: false }),
  devTools: process.env.NODE_ENV === "production" ? false : true,
});
setupListeners(store.dispatch);

const persistor = persistStore(store);
export { persistor };
// export default store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
