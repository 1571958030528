import React from 'react'
import { useLocation } from 'react-router-dom';
import { Container, Box, Typography, TableBody, Alert, Button, CircularProgress, LinearProgress, } from '@mui/material'
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useGetBatchDataQuery } from '../app/baseApi';
import { errorParser } from '../features/errorParser';

const Attendance = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const batch = searchParams.get('Batch');
    const Center = searchParams.get('Center');
    const [activeDate, setActiveDate] = React.useState("")
    const query = {
        centerId: Center,
        batchId: batch,
        date: activeDate
    }

    const { data, isSuccess, error, isLoading, isFetching } = useGetBatchDataQuery(query)
    // console.log(data, "Batch data")
    return (
        <>
            {isFetching && (<>
                <LinearProgress />
            </>)}
            {isLoading &&
                <Container fixed sx={{ py: '20px' }}>
                    <Box height="80vh" display="flex" justifyContent="center" alignItems="center" >
                        <CircularProgress />
                    </Box>
                </Container>
            }
            {isSuccess ?
                <Container fixed sx={{ py: '20px' }}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h5" color="initial">
                            Batch {data?.center?.batch?.batchName} / {data?.center?.centerName}
                        </Typography>
                        <Box flexGrow={1} />
                        <Box display="flex" alignItems="center" gap='10px'>
                            {data?.batchMonths?.map((item: any, i: number) =>
                                <Button
                                    key={i}
                                    onClick={() => setActiveDate(item)}
                                    variant={(data?.center?.batch?.months?.date === item || activeDate === item) ? "contained" : "outlined"}
                                    color="primary"
                                    size='small'
                                >
                                    {item}
                                </Button>
                            )}
                        </Box>
                    </Box>
                    <br />
                    {!data?.center?.batch?.months?.students ?
                        (<>
                            <Alert severity='error' >
                                Students not found.
                            </Alert>
                        </>)
                        :
                        (<>
                            <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
                                <Table sx={{ minWidth: 650, }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ whiteSpace: 'nowrap', position: 'sticky', left: 0, zIndex: 1, background: "#fff" }}><b>Candidate Name</b></TableCell>
                                            {data?.center?.batch?.datesMap?.map((item: any, i: number) =>
                                                <TableCell align="left" key={i}>
                                                    <b>{item}</b>
                                                    <Box display="flex" alignItems="center" gap="10px" justifyContent="space-evenly">
                                                        <b>In</b>
                                                        <b>|</b>
                                                        <b>Out</b>
                                                    </Box>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.center?.batch?.months?.students?.map((data: any, i: number) => {
                                            return (
                                                <TableRow
                                                    key={i}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" sx={{ position: 'sticky', left: 0, zIndex: 1, background: "#fff", whiteSpace: 'nowrap' }}>
                                                        <Box display="flex" gap="5px" alignItems="center">
                                                            <b>{data.studentName}</b>
                                                        </Box>
                                                    </TableCell>
                                                    {data?.attendance?.map((item: any, i: number) =>
                                                        <TableCell align="left" key={i}>
                                                            <Typography fontSize="inherit" color="inherit" sx={{ whiteSpace: 'nowrap' }}>
                                                                <b>{item?.inTime} | {item?.outTime}</b>
                                                            </Typography>
                                                        </TableCell>
                                                    )}
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>)
                    }
                </Container>
                :
                <Container fixed sx={{ py: '20px' }}>
                    <Alert severity='error' >
                        {errorParser(error) || "Something went wrong"}
                    </Alert>
                </Container>
            }
        </>
    )
}

export default Attendance
