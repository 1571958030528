import { Box, Container, Typography } from '@mui/material'
import React from 'react'

const Footer = () => {
    return (
        <>
            <Box>
                <Container fixed sx={{ py: '10px' }}>
                    <Typography variant="body1" color="initial" textAlign="center">
                        Copyright ©2024 <a href="https://www.fewerclicks.co/" target='_blank' rel="noreferrer">FewerClicks Software</a>. All Rights Reserved.
                    </Typography>
                </Container>
            </Box>
        </>
    )
}

export default Footer