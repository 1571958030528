
import React from "react";
import "./App.css";
import MainRoutes from "./routes";
import theme from "./themes/theme";
import { ThemeProvider } from "@mui/material/styles";
import "./App.css";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Toaster />
        <MainRoutes />
      </ThemeProvider>
    </>
  );
}

export default App;
