import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: "#334B7B",
            dark: "#334B7B",
        },
        secondary: {
            main: "#fff",
            light: "#fff",
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1400,
            // lg: 1280,
            xl: 1920,
        },
    },
    typography: {
        fontFamily: "Nunito Sans",
        h1: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "3.82rem",
            // 61px
            lineHeight: "120%",
        },
        h2: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "3.062rem",
            // 49px
            lineHeight: "120%",
        },
        h3: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "2.438rem",
            // 39px
            lineHeight: "120%",
        },
        h4: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "1.938rem",
            // 31px
            lineHeight: "120%",
        },
        h5: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "1.562rem",
            // 25px
            lineHeight: "120%",
        },
        h6: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "1.250rem",
            // 20px
            lineHeight: "120%",
        },
        body1: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "1.000rem",
            // 16px
            lineHeight: "120%",
        },
        body2: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "0.812rem",
            // 13px
            lineHeight: "120%",
        },
        subtitle1: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "0.625rem",
            // 10px
            lineHeight: "120%",
        },
        subtitle2: {},
    },
});


const theme = {
    ...defaultTheme,
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    background: '#F2F4F8',
                    borderRadius: "8px",
                    "& fieldset": {
                        borderColor: '#F2F4F8',
                    },
                    "&  .MuiFormHelperText-root.Mui-error": {
                        background: "#fff",
                        margin: 0,
                        paddingLeft: 10
                    },
                    "& .MuiOutlinedInput-root:hover": {
                        "& > fieldset": {
                            borderColor: "#F2F4F8"
                        }
                    },
                    "&.Mui-disabled": {
                        background: '#F2F4F8',
                        borderColor: '#F2F4F8',
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: '#F2F4F8',
                        },
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "unset",
                    borderRadius: '50px',
                },
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    borderRadius: '12px',
                },
            }
        },
        MuiTypography: {
            styleOverrides: {
                h4: {
                    [defaultTheme.breakpoints.down(900)]: {
                        fontSize: '25px'
                    },
                },
            },
        },
        // MuiStepConnector: {
        //     styleOverrides: {
        //         lineVertical: {
        //             background: 'none',
        //             borderLeft: 'none',
        //         },
        //     }
        // },
    },

};

export default theme;
