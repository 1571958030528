import React from 'react';
import { BrowserRouter, Outlet, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import PageNotFound from './404'
import { useLayoutEffect } from 'react'
import Login from '../pages/Login';
import Header from '../components/Header';
// import UploadFile from '../pages/UploadFile';
import BatchSelection from '../pages/BatchSelection';
import Attendance from '../pages/Attendance';
import { useAppSelector } from '../app/hooks';
import Footer from '../components/Footer';

// scroll to top
const ScrollToTop = ({ children }: any) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
}

const UsersProtectedRoute = () => {
    const isAuthenticated = useAppSelector((state: any) => state.user.auth)
    return isAuthenticated ? <Outlet /> : <Navigate to='/' />;
};

function MainRoutes() {
    const isAuthenticated = useAppSelector((state: any) => state.user.auth)
    return (
        <>
            <BrowserRouter>
                {isAuthenticated && <Header />}
                <ScrollToTop>
                    <Routes>
                        <Route path='/' element={<Login />} />
                        <Route path="dashboard" element={<UsersProtectedRoute />}>
                            <Route path='batch-selection' element={<BatchSelection />} />
                            <Route path='batch-attendence' element={<Attendance />} />
                        </Route>
                        <Route path='*' element={<PageNotFound />} />
                    </Routes>
                </ScrollToTop >
                {isAuthenticated && <Footer />}
            </BrowserRouter >
        </>
    );
}

export default MainRoutes;
