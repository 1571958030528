import { Container, Box, TextField, MenuItem, Button, Typography, CircularProgress } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetAllCentersQuery } from '../app/baseApi'

const BatchSelection = () => {
    const navigate = useNavigate()
    const [district, setDistrict] = React.useState("")
    const [batch, setBatch] = React.useState("");
    const [center, setCenter] = React.useState("");

    const handleSubmit = (e: any) => {
        navigate(`/dashboard/batch-attendence?Center=${center}&Batch=${batch}`)
    }

    const { data, isSuccess, isLoading } = useGetAllCentersQuery(null)
    // console.log(data)
    return (
        <Container fixed maxWidth='sm'>
            {isLoading &&
                <Box height="80vh" display="flex" justifyContent="center" alignItems="center" >
                    <CircularProgress />
                </Box>
            }
            {isSuccess &&
                <Box sx={{ height: '82vh', display: 'flex', alignItems: 'center' }}>
                    <Box sx={BoxStyles}>
                        <Typography variant="h5" color="initial">
                            Select batch
                        </Typography>
                        <br />
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <TextField
                                id='State'
                                label="State"
                                fullWidth
                                name='State'
                                select
                                required
                            >
                                <MenuItem value="madhya-pradesh">Madhya Pradesh</MenuItem>
                            </TextField>
                            <br /><br />
                            <TextField
                                id='District'
                                label="District"
                                fullWidth
                                name='District'
                                select
                                required
                                onChange={(e) => setDistrict(e.target.value)}
                                value={district}
                            >
                                <MenuItem value="Indore">Indore</MenuItem>
                                <MenuItem value="Harda">Harda</MenuItem>
                                <MenuItem value="Hoshangabad">Hoshangabad</MenuItem>
                            </TextField>
                            <br /><br />
                            <TextField
                                id='Center'
                                label="Center"
                                fullWidth
                                name='Center'
                                select
                                required
                                value={center}
                                onChange={(e) => setCenter(e.target.value)}
                            >
                                {data?.centers?.filter((item: any) => item?.centerName.includes(district)).map((item: any, i: any) =>
                                    // {data?.centers?.map((item: any, i: any) =>
                                    <MenuItem key={i} value={item?._id}>{item?.centerName}</MenuItem>
                                )}
                            </TextField>
                            <br /><br />
                            <TextField
                                id='Batch'
                                label="Batch"
                                fullWidth
                                name='Batch'
                                select
                                required
                                onChange={(e: any) => setBatch(e.target.value)}
                                value={batch}
                            >
                                {data?.centers?.find((cent: any) => cent?._id === center)?.batches.map((item: any, i: number) =>
                                    <MenuItem key={i} value={item._id}>{item.batchName}</MenuItem>
                                )}
                            </TextField>
                            <br /><br />
                            <Box textAlign="right">
                                <Button type="submit" variant="contained" color="primary" size="large">
                                    Submit
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Box>
            }
        </Container>
    )
}

export default BatchSelection


const BoxStyles = {
    boxShadow: '0px 50px 106px 11px rgba(46, 67, 117, 0.15)', boxSizing: 'border-box',
    borderRadius: '20px', p: { md: '30px', xs: '20px' }, width: '100%'
}